// Packages
import React from "react";

// Components
import Container from "~components/common/Container";
import P from "~components/typography/P";
import Button from "~components/common/Button";
import H2 from "~components/typography/H2";
import Grid from "~components/common/Grid";

interface Props {
	title: string;
	description: string;
	button: {
		text: string;
		to?: string;
		href?: string;
		onClick?: () => void;
		anchor?: string;
	};
}

const Cta: React.FC<Props> = ({ title, description, button }) => {
	return (
		<Container backgroundColor="bg-primary-600" noMargin>
			<Grid cols={2} items="center" className="py-8 md:py-16">
				<div className="flex-1">
					<H2 display as="p" className="mb-4" textColor="text-white">
						{title}
					</H2>
					<P textColor="text-white">{description}</P>
				</div>
				<div className="text-center">
					<Button
						text={button.text}
						to={button.to ? button.to : undefined}
						href={button.href ? button.href : undefined}
						onClick={button.onClick ? button.onClick : undefined}
						anchor={button.anchor ? button.anchor : undefined}
						className="w-full"
						type="white"
					/>
				</div>
			</Grid>
		</Container>
	);
};

export default Cta;
