import React from "react";
import Blockquote from "~components/typography/Blockquote";

interface Props {
	author: string;
	text: string;
}

const SectionBlockquote: React.FC<Props> = ({ author, text }) => {
	return (
		<div className="bg-primary-100">
			<div className="mx-auto max-w-[680px] py-16">
				<Blockquote author={author} text={text} />
			</div>
		</div>
	);
};

export default SectionBlockquote;
