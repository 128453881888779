import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image";

interface Props {
	imageData: IGatsbyImageData;
	alt: string;
}

const DanielThielHeaderComposition: React.FC<Props> = ({ imageData, alt }) => {
	const data = useStaticQuery(graphql`
		{
			profileImage: file(relativePath: { eq: "components/hero/daniel-thiel.png" }) {
				childImageSharp {
					gatsbyImageData(quality: 90)
				}
			}
			dotsBlue: file(relativePath: { eq: "components/hero/dots-blue.png" }) {
				childImageSharp {
					gatsbyImageData
				}
			}
			dotsOrange: file(relativePath: { eq: "components/hero/dots-orange.png" }) {
				childImageSharp {
					gatsbyImageData
				}
			}
			elipse: file(relativePath: { eq: "components/hero/elipse.png" }) {
				childImageSharp {
					gatsbyImageData
				}
			}
			greator: file(relativePath: { eq: "components/hero/TheKey_Siegel.png" }) {
				childImageSharp {
					gatsbyImageData(width: 140)
				}
			}
		}
	`);

	const image = getImage(imageData);
	const dotsBlue = getImage(data.dotsBlue);
	const dotsOrange = getImage(data.dotsOrange);
	const elipse = getImage(data.elipse);
	const greator = getImage(data.greator);

	return (
		<div className="relative flex items-end self-end md:pt-8">
			{elipse ? (
				<div className="absolute bottom-0 left-0 right-0 mx-auto w-3/4 text-center">
					<GatsbyImage image={elipse} alt="" />
				</div>
			) : null}
			{dotsBlue ? (
				<div className="absolute top-4 left-4 md:top-16 md:left-16">
					<GatsbyImage image={dotsBlue} alt="" />
				</div>
			) : null}
			{dotsOrange ? (
				<div className="absolute bottom-4 right-4 md:bottom-16 md:right-16">
					<GatsbyImage image={dotsOrange} alt="" />
				</div>
			) : null}
			{greator ? (
				<div className="absolute bottom-4 right-4 z-10 md:bottom-16 md:right-16">
					<GatsbyImage image={greator} alt="" />
				</div>
			) : null}
			{image ? <GatsbyImage image={image} alt={alt} title={alt} /> : null}
		</div>
	);
};

export default DanielThielHeaderComposition;
