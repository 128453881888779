import { PlayIcon } from "@heroicons/react/solid";
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image";
import React, { useState } from "react";
import P from "~components/typography/P";

interface Props {
	title: string;
	videoId: string;
	videoCover: IGatsbyImageData;
}

const VideoYouTube: React.FC<Props> = ({ title, videoId, videoCover }) => {
	const [accept, setAccept] = useState(false);

	const imageCover = getImage(videoCover);
	if (accept === false) {
		return (
			<button className={`group relative w-full cursor-pointer rounded`} onClick={() => setAccept(true)}>
				<div
					className={`inset-0 z-10 flex h-full w-full items-center justify-center rounded bg-black bg-opacity-80 sm:absolute`}
				>
					<div className="mx-auto max-w-md p-3 text-center">
						<P textColor="text-white" className="mb-3">
							Mit dem Klick auf das Video erklärst Du Dich einverstanden, dass Deine Daten an YouTube übermittelt werden
							und das Du die Datenschutzerklärung gelesen hast.
						</P>
						<PlayIcon className="mx-auto h-16 text-[#FF0000] md:h-32" />
					</div>
				</div>
				{imageCover ? (
					<div className="hidden rounded align-top sm:block">
						<GatsbyImage image={imageCover} alt={title} className="hidden rounded align-top sm:block" />
					</div>
				) : null}
			</button>
		);
	}

	return (
		<iframe
			className="aspect-video w-full rounded-lg shadow-lg"
			src={`https://www.youtube-nocookie.com/embed/${videoId}/?autoplay=1`}
			frameBorder={0}
			allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
			allowFullScreen
			title={title}
		/>
	);
};

export default VideoYouTube;
