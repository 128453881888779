import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";

// Components
import Container from "~components/common/Container";
import Grid from "~components/common/Grid";
import H2 from "~components/typography/H2";
import P from "~components/typography/P";

const Logos: React.FC = () => {
	const data = useStaticQuery(graphql`
		{
			huskies: file(relativePath: { eq: "logos/ec-huskies.png" }) {
				childImageSharp {
					gatsbyImageData(width: 100)
				}
			}
			greator: file(relativePath: { eq: "logos/Greator_Logo_.png" }) {
				childImageSharp {
					gatsbyImageData
				}
			}
		}
	`);

	const huskies = getImage(data.huskies);
	const greator = getImage(data.greator);

	return (
		<Container>
			<Grid cols={2}>
				<div>
					<H2>Unsere Partner</H2>
					<P>Ich bin froh starke Partner an meiner Seite zu haben, denen ich vertraue.</P>
				</div>
				<div className="grid grid-cols-2 gap-0.5">
					<div className="col-span-1 flex items-center justify-center border-2 border-gray-50 px-8 py-8">
						{huskies ? <GatsbyImage image={huskies} alt="" /> : null}
					</div>
					<div className="col-span-1 flex items-center justify-center border-2 border-gray-50 px-8 py-8">
						{greator ? <GatsbyImage image={greator} alt="" /> : null}
					</div>
				</div>
			</Grid>
		</Container>
	);
};

export default Logos;
